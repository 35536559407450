import styled from "styled-components";
import { SectionContainer } from "../SectionContainer";
import tokens from "@/tokens";


 

export const DiscordSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width:100%; 
  margin:100px 0;


  @media screen and (max-width: ${tokens.breakpoints.m}) {
    margin:50px 0 20px 0;

    .col-pri{
        margin-bottom:4rem;

    }
    .col-sec{
        align-items: center;
        justify-content: center;
    }
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    flex-direction: column !important;

    .col-pri{
        text-align:center;
        margin-bottom:4rem;
        
    }
    .col-sec{
        align-items: center;
        justify-content: center;
    }
  }

  
    li{
      color: #DAEBFF9E;
      font-size: 17px;
      font-weight: 300;
      line-height: 2.4em;
    }

  hr.bar{
    width: 75px !important;
  }

  img{
    max-width:100%;
    width:auto;
   
  }
   
  @media screen and (max-width: ${tokens.breakpoints.l}) {
    img{
      height:auto; 
    }
  }

  .title{

    span {
      color: var(--tg-theme-primary);
     } 
  }
`;

 