import styled from "styled-components";
import tokens from "@/tokens";

export const WhyChoiceBody = styled.div`
    display:flex;
    margin:0 0 100px 0;
    width: 100%;

    @media screen and (max-width: ${tokens.breakpoints.m}) {
      flex-direction:column;
      margin:50px 0;
    }

    
    @media screen and (max-width: ${tokens.breakpoints.m}) {
      flex-direction:column;
      margin:50px 0;
    }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  position:relative;
  justify-content:center;
  align-items:center;
  z-index:9;

  @media screen and (max-width: ${tokens.breakpoints.m}) {
      flex-direction:column;
  }

  .title{
    position:relative;
    margin-top: 1rem;
    text-align:center;
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:5px;
    

    
    h2 ,h2 span{
      margin-top: clamp(25px, 3vw, 40px);
      font-weight:900;
      letter-spacing: 0px;
      text-transform: uppercase;
      font-size: clamp(calc( (3rem) / 1.5), 5vw, 3rem);

       span {
        color: var(--tg-theme-primary);
       }    
    }

    p{
      max-width:65%;
      font-size: 16px;
      font-family: var(--tg-heading-font-family-old);
      margin-bottom: 0;
      color: #adb0bc;

      @media screen and (max-width: ${tokens.breakpoints.s}) {
        max-width:100%;
      }
    }
 
        
    @media screen and (max-width: ${tokens.breakpoints.s}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
`;

export const Container = styled.div`
    max-width: 1440px;
    margin:auto;
    padding-right: 15px;
    padding-left: 15px;

    @media screen and (max-width: ${tokens.breakpoints.xl}) {
      max-width:1024px;
    }
    
    @media screen and (max-width: ${tokens.breakpoints.l}) {
      max-width: 960px;
    }

    @media screen and (max-width: ${tokens.breakpoints.m}) {
      max-width: 720px;
    }

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      max-width: 100%;
    }
  
`;

export const Coluna = styled.div`
  position: relative;
  width: 100%;
  max-width: 40%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center; 
  margin:3rem 0;
  
 
  .icon{
    border:4px solid var(--tg-theme-primary);
    padding:0.5rem;
    margin-bottom:1rem;
  
    svg{
      font-size:4rem;
      color: var(--tg-theme-primary);
    }

  }
  @media screen and (max-width: ${tokens.breakpoints.m}) {
    max-width: 100%;
  }

 
`;
 
export const Content = styled.div`
  width: 100%;
 
  .title{

    h3{
        font-size: 20px;
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing: 1.2px;
        margin: 0 0 18px;
    }
  }
 
 
`;