import styled from "styled-components";
import tokens from "@/tokens";

export const SectionDiscWhyContainer = styled.section`
  display: flex;
  flex-direction: column;
  background: #0c1116;
  overflow-x: hidden;
  align-items: center;
  max-width: inherit;
  overflow-y: hidden;
  position: relative;
  background:url(assets/img/bg/bghome2.webp);
  background-position: top center;
  background-repeat: no-repeat;
  background-size:cover;
  padding:0;


    @media screen and (max-width: ${tokens.breakpoints.m}) {
      flex-direction:column;
    }
`;
 