import styled from "styled-components";
import tokens from "@/tokens";

export const DetailsBody = styled.section`
    background-image: url('assets/img/bg/sky.webp');
    display:flex;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: ${tokens.breakpoints.m}) {
      flex-direction:column;
    }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  position:relative;
  z-index:9;

  @media screen and (max-width: ${tokens.breakpoints.m}) {
      flex-direction:column;
  }
`;

export const Container = styled.div`
    max-width: 1440px;
    margin:auto;
    padding-right: 15px;
    padding-left: 15px;

    @media screen and (max-width: ${tokens.breakpoints.xl}) {
      max-width:1024px;
    }
    
    @media screen and (max-width: ${tokens.breakpoints.l}) {
      max-width: 960px;
    }

    @media screen and (max-width: ${tokens.breakpoints.m}) {
      max-width: 720px;
    }

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      max-width: 100%;
    }

    .title{

        
      @media screen and (max-width: ${tokens.breakpoints.s}) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      hr.bar{
        width:75px;
        max-width:100%;
        height:10px;
        background:transparent;
        border:none;
        color:#fff !important;
        opacity:1;
        margin-left: 0;
        position:relative;
      
    
      &::before{
          content: '';
          height: 7px;
          width: 75px;
          background-color: currentColor;
          display: inline-block;
          position: absolute;
          bottom: 0;
          right: 0;
          transform: scaleX(-1);
          -webkit-clip-path: polygon(0 0, calc(100% - 5px) 0, 100% 100%, 100% 100%, 0 100%);
          clip-path: polygon(0 0, calc(100% - 5px) 0, 100% 100%, 100% 100%, 0 100%);
        }

      }

    
    }

    .details{
      display:flex;
      gap:50px;
      margin:1rem 0 2rem 0;
    }

     
`;

export const Coluna = styled.div`
  position: relative;
  width: 100%;
  max-width: 50%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center; 

  &.col-sec{
    align-items: end;
    margin: -35px 0px -3px 0px;
    justify-content: end;
  }

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    max-width: 100%;
  }


  .title{
    position:relative;
    margin-top: clamp(25px, 3vw, 40px);

    
    h2 ,h2 span{
      margin-top: clamp(25px, 3vw, 40px);
      font-weight:900;
      letter-spacing: 0px;
      text-transform: uppercase;
      font-size: clamp(calc( (3rem) / 1.5), 5vw, 3rem);

       span.text-border {
        -webkit-text-stroke: 2px currentColor;
        -webkit-text-fill-color: transparent;
       }

     
    }
    
  }

 .imageDetails{
    vertical-align: middle;
    margin: -35px 0px -3px 0px;
    z-index: 3;
    max-width:100%;
    width:auto;
    height:auto;
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    h2{
      text-align:center ;
    }
 }
`

export const DetailsItem = styled.div`
 display:flex;


 .title{
  text-align:center;
 }

 p{
  color:#fff;
  font-size:16px;
  font-weight:600;
 }
 h3{
  font-size:30px;
  font-weight:800;
 }

`;
