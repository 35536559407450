import React from "react";
import Image from "next/image";

import discordMonitor from "public/assets/img/discord/discordMonitor.webp";
import content from "public/assets/img/discord/new-content.jpeg";

import { DiscordSectionContainer } from "./styles";
import { Container, Coluna, Row } from "../Details/styles";

import useScreenSize from "@/hooks/useScreenSize";

import { CustomAcquireButton } from "../CustomAcquireButton";
import { BsDiscord } from "react-icons/bs";

export const DiscordSection = () => {
  const { isMobile } = useScreenSize();

  return (
   
      <DiscordSectionContainer>
        <Coluna className="col-pri">
          <Row>
            <Container>
              <div className="title">
                <h2>
                  Tenha acesso ao <br />
                  nosso <span>discord</span>
                </h2>
                <hr className="bar" />
              </div>
              <ul>
                <li>Melhores boosters</li>
                <li>Melhores coachs</li>
                <li>Acompanhamento Ao vivo</li>
                <li>Suporte e Atendimento 24 Horas</li>
              </ul>
              <div>
                <CustomAcquireButton
                  link="https://discord.gg/B9YM7EAqCD"
                  target="_blank"
                >
                  <BsDiscord />
                  <span>Discord</span>
                </CustomAcquireButton>
              </div>
            </Container>
          </Row>
        </Coluna>

        <Coluna className="col-sec">
          <Image
            src={discordMonitor}
            alt="conteúdo discord"
            className="content"
            width={isMobile ? 193 : 990}
            height={isMobile ? 80 : 670}
          />
        </Coluna>
      </DiscordSectionContainer>
     
  );
};
