import React from "react";
import Image from "next/image";
import Spartan from "public/assets/img/slider/spartan.webp";

import { WhyChoiceBody, Container, Row, Coluna, Content } from "./styles";
import {
  MdOutlineSupportAgent,
  MdOutlinePrivateConnectivity,
} from "react-icons/md";
import { IoGameController, IoShieldCheckmark } from "react-icons/io5";

import Link from "next/link";

export const WhyChoice = () => {
  return (
    <WhyChoiceBody>
      <Container>
        <Row>
          <div className="title">
            <h2>
              POR QUE ESCOLHER <br /> <span className="">  A ELOJOB RISE?</span>
            </h2>
            <p>
              A Elojob Rise tem os melhores boosters para concluir o seu
              serviço com agilidade, profissionalismo e um preço super barato,
              além disso damos sempre o melhor apoio aos nossos clientes,adquira
              o seu job com quem entende do assunto, Elojob Rise
            </p>
          </div>

          <Content>
            <Row>
              <Coluna>
                <div className="icon">
                  <MdOutlineSupportAgent />
                </div>
                <div className="title">
                  <h3>SUPORTE DE QUALIDADE</h3>
                  <p>
                    Suporte 24 horas via Whatsapp ou Instagram para sanar
                    quaisquer dúvidas sobre o seu serviço!
                  </p>
                </div>
              </Coluna>
              <Coluna>
                <div className="icon">
                  <IoGameController />
                </div>
                <div className="title">
                  <h3>JOGADORES QUALIFICADOS</h3>
                  <p>
                    Possuímos Boosters profissionais para garantir rapidez e
                    eficiência na entrega dos serviços.
                  </p>
                </div>
              </Coluna>
            </Row>
            <Row className="spaceRow">
              <Coluna className="col pri">
                <div className="icon">
                  <IoShieldCheckmark />
                </div>
                <div className="title">
                  <h3>SEGURANÇA NA COMPRA</h3>
                  <p>
                    Proteção garantida para suas compras! A satisfação do
                    cliente é nossa prioridade.
                  </p>
                </div>
              </Coluna>
              <Coluna className="col sec">
                <div className="icon">
                  <MdOutlinePrivateConnectivity />
                </div>
                <div className="title">
                  <h3>SEGURANÇA E PRIVACIDADE</h3>
                  <p>
                    Utilizamos VPN e chat OFF, ninguém pode saber quando
                    estivermos logado na conta, além de manter sua conta em
                    segurança na nossa base de dados.
                  </p>
                </div>
              </Coluna>
            </Row>
          </Content>
        </Row>
      </Container>
    </WhyChoiceBody>
  );
};
